import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function OffeneStellen({ data: { hands }, location }) {
  return (
    <Layout
      location={location}
      title={"Gartenbau Dannenfelser | Offene Stellen"}
      description={
        "Wir suchen zur Verstärkung unseres Teams:  Engagierte und leistungsfähige Facharbeiter*innen im Garten & Landschaftsbau. Gute Fach- und Pflanzenkenntnisse, sowie freundliches Auftreten sind Voraussetzung.  Wir bieten Ihnen einen sicheren Arbeitsplatz in unserem Team mit leistungsgerechter Bezahlung."
      }
    >
      <GatsbyImage
        image={getImage(hands)}
        alt={"Bild: Hände halten Erde, aus der ein Pflänzlichen sprießt."}
        className={"image"}
      />

      <div className={"text"}>
        <p className={"bold"}>Wir suchen zur Verstärkung unseres Teams:</p>

        <p>
          Engagierte und leistungsfähige Facharbeiter*innen im Garten &amp;
          Landschaftsbau. Gute Fach- und Pflanzenkenntnisse, sowie freundliches
          Auftreten sind Voraussetzung.
        </p>

        <p>
          Wir bieten Ihnen einen sicheren Arbeitsplatz in unserem Team mit
          leistungsgerechter Bezahlung.
        </p>

        <p>
          Senden Sie uns Ihre Bewerbung mit Lebenslauf und Zeugnissen,
          vorzugsweise per E-Mail an:{" "}
          <a
            style={{ whiteSpace: "nowrap" }}
            href={"mailto:verwaltung@gartenbau-dannenfelser.de"}
          >
            verwaltung@gartenbau-dannenfelser.de
          </a>
          .
        </p>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    hands: file(relativePath: { eq: "hands.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;
